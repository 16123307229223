import React from "react";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import svg from "./img/top.svg";
import { motion } from "framer-motion";

const textAnimation = {
  hidden: {
    y: 150,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 1, // Длительность анимации (в секундах)
      ease: "easeInOut",
    },
  }),
};

const initialComments = [
  {
    coment: "Здесь может быть ваш отзыв",
    bgTop: svg,
    name: "-Арман",
    profession: "Гастроэнтеролог",
  },
  {
    coment: "Здесь может быть ваш отзыв",
    bgTop: svg,
    name: "-Арман",
    profession: "Гастроэнтеролог",
  },
  {
    coment: "Здесь может быть ваш отзыв",
    bgTop: svg,
    name: "-Арман",
    profession: "Гастроэнтеролог",
  },
];

const Coments = () => {
  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <NextArrow className="m-5" />,
    prevArrow: <PrevArrow />,
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.4 }}
      className="bg-custom-slider pb-5 pt-lg-5"
    >
      <div className="container pb-lg-5">
        <div className="text-center pt-5">
          <motion.span
            variants={textAnimation}
            custom={1}
            className="custom-letter custom-text-color font-bold"
          >
            ОТЗЫВЫ
          </motion.span>
          <motion.h2
            variants={textAnimation}
            custom={2}
            className="fs-3 font-bold  p-3"
          >
            Что люди говорят о нас
          </motion.h2>
        </div>
        <motion.div
          variants={textAnimation}
          custom={3}
          className="border bg-light m-4"
        >
          <Slider {...settings}>
            {initialComments.map((item, index) => {
              return (
                <div
                  key={index}
                  className="pt-5 pb-5 d-flex flex-column align-items-center gap-4"
                >
                  <img className="mt-4" src={item.bgTop} alt="" />
                  <p className="lh-sm">{item.coment}</p>
                  <div className="text-center mb-5 d-flex flex-column">
                    <span className="fs-4 font-bold ">{item.name}</span>
                    <span className="fs-10">{item.profession}</span>
                  </div>
                </div>
              );
            })}
          </Slider>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Coments;
