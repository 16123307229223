export const inputType = [
  {
    id: 1,
    type: "string",
    name: "Section1",
    description: "Возраст",
    isRequired: true,
  },
  {
    id: 2,
    type: "select",
    name: "Section2",
    description: "Пол (муж/жен)",
    isRequired: true,
  },
  {
    id: 3,
    type: "text",
    name: "Section3",
    description: "Гемоглобин, HGB, г/л",
    isRequired: true,
  },
  {
    id: 4,
    type: "text",
    name: "Section4",
    description: "Эритроциты, RBC, 10^12/л",
    isRequired: false,
  },
  {
    id: 5,
    type: "text",
    name: "Section5",
    description: "Гематокрит, HCT, %",
    isRequired: false,
  },
  {
    id: 6,
    type: "text",
    name: "Section6",
    description: "Средний объём эритритроцита, MCV, фл",
    isRequired: false,
  },
  {
    id: 7,
    type: "text",
    name: "Section7",
    description: "Среднее содержание Hb в эритроците, MCH, пг",
    isRequired: false,
  },
  {
    id: 8,
    type: "text",
    name: "Section8",
    description: "Средняя концентрация Hb в эритроците, MCHC, г/л",
    isRequired: false,
  },
  {
    id: 9,
    type: "text",
    name: "Section9",
    description: "Распределение эритроцитов по объёму, RDW (CV), %",
    isRequired: false,
  },
  {
    id: 10,
    type: "text",
    name: "Section10",
    description: "Тромбоциты, PLT, 10^9/л",
    isRequired: false,
  },
  {
    id: 11,
    type: "text",
    name: "Section11",
    description: "Средний объём тромбоцита, MPV, фл",
    isRequired: false,
  },
  {
    id: 12,
    type: "text",
    name: "Section12",
    description: "Лейкоциты, WBC, 10^9/л",
    isRequired: false,
  },
  {
    id: 13,
    type: "text",
    name: "Section13",
    description: "Нейтрофилы, NEU%, %",
    isRequired: false,
  },
  {
    id: 14,
    type: "text",
    name: "Section14",
    description: "Эозинофилы, EOS%, %",
    isRequired: false,
  },
  {
    id: 15,
    type: "text",
    name: "Section15",
    description: "Базофилы, BAS%, % ",
    isRequired: false,
  },
  {
    id: 16,
    type: "text",
    name: "Section16",
    description: "Моноциты, MON%, %",
    isRequired: false,
  },
  {
    id: 17,
    type: "text",
    name: "Section17",
    description: "Лимфоциты, LYM%, %",
    isRequired: false,
  },
  {
    id: 18,
    type: "text",
    name: "Section18",
    description: "СОЭ по Панченкову, мм/час",
    isRequired: false,
  },
  {
    id: 19,
    type: "text",
    name: "Section19",
    description: "Дата анализа, дд.мм.гггг",
    isRequired: true,
  },
];

export const inputType2 = [
  { id: 1, type: "text", name: "Section20", description: "Возраст" },
  { id: 2, type: "text", name: "Section21", description: "Муж/Жен" },
];

export const otherFields = [
  "tsh",
  "free_t3",
  "free_t4",
  "anti_tpo",

  "glucose",
  "insulin",
  "fructosamine",
  "homa_index",
  "serum_iron",
  "ferritin",
  "tibc",
  "transferrin",
];

export const invidualInputs = [
  {
    id: 1,
    type: "string",
    name: "Section1",
    description: "Возраст",
    isRequired: true,
  },
  {
    id: 2,
    type: "select",
    name: "Section2",
    description: "Пол (муж/жен)",
    isRequired: true,
  },
  {
    id: 3,
    type: "text",
    name: "Section3",
    description: "Гемоглобин, HGB (HB), г/л",
    isRequired: true,
  },
  {
    id: 4,
    type: "text",
    name: "Section4",
    description: "Эритроциты, RBC, 10^12/л",
    isRequired: false,
  },
  {
    id: 5,
    type: "text",
    name: "Section5",
    description: "Гематокрит, HCT, %",
    isRequired: false,
  },
  {
    id: 6,
    type: "text",
    name: "Section6",
    description: "Средний объём эритритроцита, MCV, фл",
    isRequired: false,
  },
  {
    id: 7,
    type: "text",
    name: "Section7",
    description: "Среднее содержание Hb в эритроците, MCH, пг",
    isRequired: false,
  },
  {
    id: 8,
    type: "text",
    name: "Section8",
    description: "Средняя концентрация Hb в эритроците, MCHC, г/л",
    isRequired: false,
  },
  {
    id: 9,
    type: "text",
    name: "Section9",
    description: "Распределение эритроцитов по объёму, RDW (CV), %",
    isRequired: false,
  },
  {
    id: 10,
    type: "text",
    name: "Section10",
    description: "Тромбоциты, PLT, 10^9/л",
    isRequired: false,
  },
  {
    id: 11,
    type: "text",
    name: "Section11",
    description: "Средний объём тромбоцита, MPV, фл",
    isRequired: false,
  },
  {
    id: 12,
    type: "text",
    name: "Section12",
    description: "Лейкоциты, WBC, 10^9/л",
    isRequired: false,
  },
  {
    id: 13,
    type: "text",
    name: "Section13",
    description: "Нейтрофилы, NEU%, %",
    isRequired: false,
  },
  {
    id: 14,
    type: "text",
    name: "Section14",
    description: "Эозинофилы, EOS%, %",
    isRequired: false,
  },
  {
    id: 15,
    type: "text",
    name: "Section15",
    description: "Базофилы, BAS%, % ",
    isRequired: false,
  },
  {
    id: 16,
    type: "text",
    name: "Section16",
    description: "Моноциты, MON%, %",
    isRequired: false,
  },
  {
    id: 17,
    type: "text",
    name: "Section17",
    description: "Лимфоциты, LYM%, %",
    isRequired: false,
  },
  {
    id: 18,
    type: "text",
    name: "Section18",
    description: "СОЭ по Панченкову, мм/час",
    isRequired: false,
  },

  {
    id: 19,
    type: "text",
    name: "serum_iron",
    description: "Железо в сыворотке, мкмоль/л",
    isRequired: false,
  },
  {
    id: 20,
    type: "text",
    name: "ferritin",
    description: "Ферритин, мкг/л",
    isRequired: false,
  },
  {
    id: 21,
    type: "text",
    name: "tibc",
    description:
      "Общая железосвязывающая способность сыворотки, ОЖСС, мкмоль/л",
    isRequired: false,
  },
  {
    id: 22,
    type: "text",
    name: "transferrin",
    description: "Трансферрин, г/л",
    isRequired: false,
  },
  {
    id: 23,
    type: "text",
    name: "tsh",
    description: "Тиреотропный гормон, ТТГ, мкМЕ/мл (мкЕД/мл, мЕД/л)",
    isRequired: false,
  },
  {
    id: 24,
    type: "text",
    name: "free_t3",
    description: "Трийодтиронин свободный, Т3 свободный, пмоль/л",
    isRequired: false,
  },
  {
    id: 25,
    type: "text",
    name: "free_t4",
    description: "Тироксин свободный, Т4 свободный, пмоль/л",
    isRequired: false,
  },
  {
    id: 26,
    type: "text",
    name: "anti_tpo",
    description: "Антитела к тиреопероксидазе, АТ-ТПО, МЕ/мл",
    isRequired: false,
  },

  {
    id: 27,
    type: "text",
    name: "glucose",
    description: "Глюкоза, ммоль/л",
    isRequired: false,
  },
  {
    id: 28,
    type: "text",
    name: "insulin",
    description: "Инсулин, мкЕд/мл",
    isRequired: false,
  },
  {
    id: 29,
    type: "text",
    name: "fructosamine",
    description: "Фруктозамин, мкмоль/л",
    isRequired: false,
  },
  
  {
    id: 30,
    type: "text",
    name: "gg",
    description: "Гликированный гемоглобин, %",
    isRequired: false,
  } 
];
